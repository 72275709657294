<template>
  <section>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return { };
  },
  mounted() {
    // this.$store.watch(
    //   (state) => {
    //     return state.path;
    //   },
    //   ((newVal, oldVal) => {
    //     if (newVal !== oldVal) {
    //       this.currentComponent = newVal;
    //       console.log(newVal);
    //       // this.resetState();
    //       // this.setFlowEnvironment(newVal);
    //       // this.$forceUpdate();
    //     }
    //   }).bind(this),
    //   {}
    // );
  },
  watch: {},
  methods: { },
};
</script>
<style lang="scss" scope>
// @import "tailwindcss/base";
// @import "tailwindcss/custom-forms";
</style>
